import one from '../assets/svg/projects/milktech.svg'
import two from '../assets/svg/projects/libro.png'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Dev House',
        projectDesc: 'Milktech.io, a leader in custom software development in Mexico and the US, drives digital transformation with cloud, blockchain, and corporate solutions. Innovation and passion define our global team.',
        tags: ['Software Development', 'Cloud Solutions', 'Blockchain', 'Digital Transformation', 'Web Development', 'Enterprise Solutions'],
        code: 'https://github.com/sreerag-rajan/historythroughmovies',
        demo: 'https://milktech.io',
        image: one
    },
    {
        id: 2,
        projectName: 'Book - La Grandeza de Pensar Distinto',
        projectDesc: '"In La Grandeza de Pensar Distinto, I share my journey of harnessing ADHD as a creative ally. Discover personal strategies and empower yourself in mental uniqueness."',
        tags: ['TDAH', 'Social','Book'],
        code: 'https://github.com/sreerag-rajan/sportsjam-clone',
        demo: 'https://a.co/d/cLoR0yH',
        image: two
    },
    
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/