export const blogData = [
    {
        id: 1,
        title: 'Polygon (MATIC): Desbloqueando el potencial de la tecnología blockchain para todos.',
        description: 'Bueno, Bonito, Barato y Seguro. En los últimos años, la tecnología blockchain ha captado la atención del mundo entero. Su capacidad...',
        date: 'May 16, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:3200/format:webp/1*_khlcV6DZQvUqmB3ry-zeA.png',
        url: 'https://medium.com/@alfredo.cendejas/polygon-matic-desbloqueando-el-potencial-de-la-tecnología-blockchain-para-todos-c6a453b5acee'
    },
    {
        id: 2,
        title: 'Scrum para Todos',
        description: 'A nivel personal siempre he tenido el ahnelo de poder enseñar scrum a todas las personas con las que trabajo, ¿Por que?, Pues es puer sencillo una vez que yo supe que es y como se aplica mis proyectos se hicieron...',
        date: 'Feb 18, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*-JhbxmjjtT6By3To3TAjZQ.jpeg',
        url: 'https://medium.com/@alfredo.cendejas/scrum-para-todos-5318d790a5f5'
    },
    {
        id: 3,
        title: 'Smart Contract ¿que carajos es?',
        description: 'Los smart contracts son programas informáticos que se ejecutan automáticamente cuando se cumplen ciertas condiciones predefinidas. Estas condiciones se establecen en el código del contrato y se ejecutan...',
        date: 'Feb 15, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*PSXa6viWCRyXhAvDtQIf0w.jpeg',
        url: 'https://medium.com/@alfredo.cendejas/smart-contract-que-carajos-es-5d47cbc850aa'
    },
    {
        id: 4,
        title: 'Blockchain sin brechas, no entiendas todo.',
        description: 'La Web 3.0 representa una nueva era en la que la descentralización, la transparencia y la participación activa son los pilares fundamentales...',
        date: 'May 26, 2023',
        image: 'https://media.licdn.com/dms/image/D5612AQEVHUbCR3iZzA/article-cover_image-shrink_720_1280/0/1685163154888?e=1717632000&v=beta&t=SywSzNuY6U7Y3ZfcKE1j7gYiWIk-EnJ_kEEgnL1ISTY',
        url: 'https://www.linkedin.com/pulse/blockchain-sin-brechas-entiendas-todo-alfredo-gutierrez-cendejas/?trackingId=cDR7dGtGRQS%2Bch3IqAJrSA%3D%3D'
    },
    {
        id: 4,
        title: 'Scrum para todos (2024)',
        description: 'En mi experiencia personal, descubrir y aplicar Scrum transformó completamente mi enfoque hacia la gestión de proyectos...',
        date: 'Feb 22, 2024',
        image: 'https://media.licdn.com/dms/image/D4E12AQFfbi-cvPfQcQ/article-cover_image-shrink_720_1280/0/1708640493356?e=1717632000&v=beta&t=zZznPnorU8Rdm44wfoJ9Ted2J7sma4KkfqIv1biC7yo',
        url: 'https://www.linkedin.com/pulse/scrum-para-todos-2024-alfredo-gutierrez-cendejas-v77me/?trackingId=cDR7dGtGRQS%2Bch3IqAJrSA%3D%3D'
    },
    
]



