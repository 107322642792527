import resume from '../assets/pdf/resume.pdf'
import perfil from '../assets/png/alfredocendejas.png'

export const headerData = {
    name: 'Alfredo Cendejas',
    title: "Agile Tech Entrepreneur",
    desciption:"Empowering the digital world 🌐✨ with cutting-edge technology solutions. Agile enthusiast 🏃💼, blockchain pioneer 🛠️🔗, and passionate innovator 🚀. Dive into a world where tech meets agility, and creativity sparks endless possibilities 💡🌟. Join me on this exciting journey to reshape the future, one project at a time 🌱🔧. Because in the realm of tech, the only constant is change.",
    image: perfil,
    resumePdf: resume
}
