export const educationData = [
    {
        id: 1,
        institution: 'Instituto Tecnológico de Morelia',
        course: 'Computerstystems engineer',
        startYear: '2011',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'Instituto Tecnológico de Morelia',
        course: 'Software Engineering Specialization',
        startYear: '2015',
        endYear: '2017'
    },
    {
        id: 3,
        institution: 'Universidad del Valle de México',
        course: 'International Agile Management Certification, Scrum Master',
        startYear: '2023',
        endYear: '2023'
    },
]