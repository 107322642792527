
import { SiKubernetes, SiGooglecloud } from "react-icons/si";
import { DiScrum } from "react-icons/di";
import { RiTeamFill } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { AiOutlineCloudServer } from "react-icons/ai";
import { FaServer, FaBtc, FaSchool ,FaChalkboardTeacher } from "react-icons/fa";

 

export const servicesData = [
     {
         id: 1,
         title: 'Agile Transformation Consulting',
         icon: <DiScrum/>
     },
    {
        id: 2,
        title: 'DevOps Strategy',
        icon: <SiKubernetes /> 
    },
    {
        id: 3,
        title: 'Blockchain Solution',
        icon: <FaBtc />
    },
     {
         id: 4,
         title: 'Cloud Migration',
         icon: <AiOutlineCloudServer />
     },
     {
         id: 5,
         title: 'Website Development',
         icon: <FaChalkboardTeacher />
     },

    {
        id: 6,
        title: 'Project Management',
        icon: <RiTeamFill />
    },
    {
        id: 7,
        title: 'Workshops & Training',
        icon: <GiTeacher />
    },
     {
         id: 8,
         title: 'Consulting Cloud',
         icon: <SiGooglecloud />
     }, 
    {
        id: 9,
        title: 'Methodology Creator',
        icon: <FaSchool />
    },
     {
         id: 10,
         title: 'Infraestructure Consulting', 
         icon: <FaServer />
     },

]
