import scrum from '../../src/assets/png/scrum.png';
import devops from '../../src/assets/png/devops.png';
import ibm from '../../src/assets/png/ibm.png';

export const achievementData = {
    bio : "I've honed my expertise in technology and agile methodologies through dedicated study in Computer Systems Engineering and a continuous pursuit of professional development. My passion for innovation is underscored by an extensive list of certifications, reflecting a mastery in agile frameworks and a commitment to staying at the forefront of technological advancement.",
    achievements : [
        {
            id : 1,
            title : 'Scrum® Master Certification',
            details : 'Scrum Study',
            date : 'Jan 25, 2024',
            field : 'Project Coordination',
            image : scrum
        },
        {
            id : 2,
            title : 'Enterprise Design Thinking',
            details : 'IBM',
            date : 'Jun 13, 2022',
            field : 'Project Coordination',
            image : ibm
        },
        {
            id : 3,
            title : 'DevOps Certified Expert (DCE)',
            details : 'CerTeach',
            date : 'Nov 03, 2021',
            field : 'Devops',
            image : devops
        }
    ]

    
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/