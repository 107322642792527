export const experienceData = [
    {
        id: 1,
        company: 'EON',
        jobtitle: 'Chief Technology Officer',
        startYear: '2022',
        endYear: '2024'
    },
    {
        id: 2,
        company: 'Betterware de México',
        jobtitle: 'DevOps Tech Lead',
        startYear: '2021',
        endYear: '2022'
    },
    {
        id: 3,
        company: 'Xifra Business Group',
        jobtitle: 'Chief Technology Officer',
        startYear: '2020',
        endYear: '2021'
    },
    {
        id: 4,
        company: 'Arrendamas',
        jobtitle: 'Project Manager',
        startYear: '2018',
        endYear: '2020'
    },
    {
        id: 5,
        company: 'Grupo TYC',
        jobtitle: 'Chief Systems',
        startYear: '2018',
        endYear: '2018'
    },
    
]